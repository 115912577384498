<script lang="ts" setup>
import {SpeedInsights} from "@vercel/speed-insights/nuxt"

useSeoMeta({
  ogTitle: 'Ayşe & Övünç, 14 Eylül 2024',
  ogDescription: 'Bu özel günümüzde sizleri de aramızda görmekten mutluluk duyarız.',
  ogImage: 'https://ayseovunc.com/davetiye.png',
  ogSiteName: 'Ayşe & Övünç, 14 Eylül 2024',
  ogType: "website"
})
</script>
<template>
  <SpeedInsights/>
  <slot/>
</template>